import React, { useState, useEffect } from "react";
import { modifMedio } from "../helpers/rutaMedios";
import { Modal, Button } from "react-bootstrap";
import { getMedios } from "../helpers/rutaMedios";
import '../css/modalformalert.css'; // Estilos personalizados
import { FaThumbsUp } from "react-icons/fa"; // Importar el ícono de pulgar hacia arriba


const ModalFormMedio = ({ medio, handleClose }) => {
  console.log(medio);
  const id = localStorage.getItem("id");
  const [formValues, setFormValues] = useState({
    medio:medio.medios.medio,
  });

  const [showSuccessModal, setShowSuccessModal] = useState(false);

  const handleChange = (e) => {
    setFormValues({
      ...formValues,
      [e.target.name]: e.target.value,
    });
  };
  const handleSubmit = (e) => {
    console.log(medio.medios._id);
    e.preventDefault();
    modifMedio(formValues, medio.medios._id).then((respuesta) => {
      setShowSuccessModal(true);  // Mostrar modal de éxito
    });
  };


  const handleSuccessClose = () => {
    setShowSuccessModal(false);
    handleClose();  // Cerrar modal principal después del éxito
  };
  
  const [medios, setMedios] = useState({
    data: {},
    loading: true,
  });
  useEffect(() => {
    getMedios().then((medios) => {
      setMedios({
        data: medios,
        loading: false,
      });
    });
  }, []);

  return (
    <>
      {!medios.loading && (
        <form onSubmit={handleSubmit}>
          <Modal.Body>
            <div className="form-group mt-3">
              <label>Medio de Pago</label>
              <input
                rows="1"
                type="text"
                className="form-control"
                name="medio"
                maxLength="50"
                minLength="5"
                required
                value={formValues.medio}
                onChange={handleChange}
              />
            </div>

          </Modal.Body>
          <Modal.Footer>
            <Button type="submit" variant="dark">
              Guardar
            </Button>
          </Modal.Footer>
        </form>
      )}
      ;

      {/* Modal de éxito estilo imagen */}
      <Modal show={showSuccessModal} onHide={handleSuccessClose} centered>
        <div className="custom-modal">
          <div className="custom-modal-icon">
            <FaThumbsUp /> {/* Manito de "OK" */}
          </div>
          <Modal.Body className="custom-modal-body">
            <h4>Info</h4>
            <p>¡Los cambios se guardaron correctamente!</p>
          </Modal.Body>
          <Button className="custom-modal-button" onClick={handleSuccessClose}>
            Aceptar
          </Button>
        </div>
      </Modal>
    </>
  );
};

export default ModalFormMedio;
