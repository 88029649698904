import React from "react";
import { Modal } from "react-bootstrap";
import ModalFormGastoActual from "./ModalFormGastoActual";

const ModalGastoActual = ({ show, handleClose, gasto }) => {
  console.log(gasto);
  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Aprobar y Cargar Gasto</Modal.Title>
      </Modal.Header>
      <ModalFormGastoActual gasto={gasto} handleClose={handleClose} />
    </Modal>
  );
};

export default ModalGastoActual;
