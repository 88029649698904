import React, { useState, useEffect } from "react";
import { Table } from "react-bootstrap";
import { getMedios, delMedio, getMedioId } from "../helpers/rutaMedios";
import ModalMedio from "./ModalMedio";
import "../css/tablemedios.css";

const TableMedios = () => {
  let id_medio = "";

  const [medios, setMedios] = useState({
    data: {},
    loading: true,
  });

  const [medio, setMedio] = useState({});
  const [show, setShow] = useState(false);

  useEffect(() => {
    consultaMedios();
  }, []);

  const consultaMedios = (desde) => {
    getMedios(desde, 1000).then((datos) => {
      console.log(datos);
      setMedios({
        data: datos,
        loading: false,
      });
    });
  };

  // console.log(comandas.data[0]);

  const handleClose = () => {
    setShow(false);
    consultaMedios();
  };

  const handleShow = () => setShow(true);

  const modificaMedio = (id) => {
    id_medio = id;
    getMedioId(id_medio).then((resp) => {
      console.log(resp);
      setMedio(resp);

      handleShow();
    });
  };

  const deleteMedio = (id) => {
    let validar = window.confirm("está seguro que desea borrar el Medio de Pago?");
    if (validar) {
      delMedio(id).then((resp) => {
        consultaMedios();
      });
    }
  };

  let count = 0;

  return (
    <div className="table-container">
      {!medios.loading && (
        <>
          <div className="col-12 mt-4"></div>
          <div>
            <Table striped bordered hover className="table sticky">
              <thead>
                <tr className="header">
                  <th className="borra sticky">Modif/Elim</th>
                  <th className="medio sticky">Medios</th>
                  <th className="sticky"></th>
                </tr>
              </thead>

              <tbody>
                {medios.data.medios.map((medio) => (
                  <>
                    {medio.activo && (
                      <tr key={medio._id}>
                        
                        {/* <td>{cliente.lista.lista}</td>
                        <td>{cliente.codprod.descripcion}</td> */}
                        {/* <td>{usuario.nombre}</td>*/}
                        {/* {(count = count + 1)} */}
                        <td className="borra">
                          <button
                            id="acepto"
                            className="btn btn-primary"
                            onClick={() => {
                              modificaMedio(medio._id);
                            }}
                          >
                            <i
                              className="fa fa-pencil-square-o"
                              aria-hidden="true"
                            ></i>
                          </button>
                          <button
                            id="acepto"
                            className="btn btn-danger ml-2"
                            onClick={() => {
                              deleteMedio(medio._id);
                            }}
                          >
                            <i className="fa fa-trash-o" aria-hidden="true"></i>
                          </button>
                        </td>
                        <td className="medio">{medio.medio}</td>
                      </tr>
                    )}
                  </>
                ))}
              </tbody>
            </Table>
            {/* <h1>{count}</h1> */}
          </div>
          <ModalMedio show={show} handleClose={handleClose} medio={medio} />
        </>
      )}
    </div>
  );
};

export default TableMedios;
