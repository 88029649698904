import React from "react";
import { Modal } from "react-bootstrap";
import ModalFormComandaActual from "./ModalFormComandaActual";

const ModalComandapagado = ({ show, handleClose, comandapagado }) => {
  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Modificar comanda</Modal.Title>
      </Modal.Header>
      <ModalFormComandaActual comandapagado={comandapagado} handleClose={handleClose} />
    </Modal>
  );
};

export default ModalComandapagado;
