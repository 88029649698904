import React, { useState, useEffect } from "react";
import GetDataInvoiceAdmin from "../report/GetDataInvoiceAdmin";
import { getComandapagados, delComandapagado, modifComandapagado, getComandapagadoId } from "../helpers/rutaComandapagados";
import { modifProducserv, getProducservId } from "../helpers/rutaProducservs";
import styled from "styled-components";
import axios from "axios";
import _ from "lodash";
import Table from "./TableContainer";
import ModalComandapagado from "../components/ModalComandapagado";
import ModalAsignar from "../components/ModalAsignar";
import "../css/tablecomandas.css";

function AppComandaPReactTable() {
  const [showDatesRange, setShowDatesRange] = useState(false);
  const [show, setShow] = useState(false);
  const [showMasivo, setShowMasivo] = useState(false);
  var arrayComandas=[];

  const [comandapagados, setComandapagados] = useState({
    data: {},
    loading: true,
  });
  const [comandapagado, setComandapagado] = useState({});
  const [data, setData] = useState([]);
  const [carga, setCarga] = useState(false);

  const [fechaDesde, setFechaDesde] = useState('')
  const [fechaHasta, setFechaHasta] = useState('')

  const getRangeFechas = () => {
    setFechaDesde(document.getElementById('inputdesde').value);
    setFechaHasta(document.getElementById('inputhasta').value);
    setShowDatesRange(true);
  }

  useEffect(() => {
    // "https://backditripollo.us-3.evennode.com/comandasactivas"
    // axios.get('http://localhost:3004/comandasinformes', {
    axios.get('https://backdistripruebas.us-3.evennode.com/comandapagadosinformes', {
      params: {
        fechaDesde: fechaDesde,
        fechaHasta: `${fechaHasta}T23:59:59.000Z`,
      }
    })
      .then((res) => {
        setData(res.data.comandapagados);
        setCarga(true);
      })
      .catch((err) => console.log(err));
  }, [fechaDesde, fechaHasta]);

  const Styles = styled.div`
    sticky: true;
    padding: 0rem;

    table {
      sticky: true;
      // background-color: #548fcd;
      color: black;
      border-spacing: 0;
      border: 1px solid black;
      font-size: 13px;
      z-index: 1;

      th {
        sticky: true;
        background-color: #778899;
        font-size: 12px;
        text-align: center;
        height: 10rem;
        // position: sticky;
        top: 100;
        z-index: 1;
      }
      ,
      td {
        sticky: true;
        margin: 0;
        padding: 0.5rem;
        border-bottom: 1px solid black;
        border-right: 1px solid black;
        // background-color: #548f0a;
        //background-color: #f0f2eb;
        font-size: 13px;
        // top: 100;
        // z-index: 1;

        :last-child {
          border-right: 0;
        }

        input {
          // sticky: true;
          font-size: 1rem;
          padding: 0;
          margin: 0;
          border: 2;
        }
      }
    }

    .pagination {
      padding: 0.5rem;
      //este me da el color de la barra de busqueda
      background-color: #778899;
      font-size: 15px;
      font-weight: bold;
    }

    &.sticky {
      overflow: scroll;
      header,
      footer {
        position: sticky;
        z-index: 1;
        // width: fit-content;
      }
    }

    .header {
      top: 0;
      box-shadow: 0px 3px 3px #ccc;
      position: sticky;
      z-index: 10;
    }

    [data-sticky-td] {
      // position: sticky;
      position: absolute;
      z-index: 0;
    }
  `;

  function dateBetweenFilterFn(rows, id, filterValues) {
    const sd = filterValues[0] ? new Date(filterValues[0]) : undefined;
    const ed = filterValues[1] ? new Date(filterValues[1]) : undefined;

    if (ed || sd) {
      return rows.filter((r) => {
        const cellDate = new Date(r.values[id]);

        if (ed && sd) {
          return cellDate >= sd && cellDate <= ed;
        } else if (sd) {
          return cellDate >= sd;
        } else if (ed) {
          return cellDate <= ed;
        }
      });
    } else {
      return rows;
    }
  }

  // filtro fecha
  function DateRangeColumnFilter({
    column: { filterValue = [], preFilteredRows, setFilter, id },
  }) {
    const [min, max] = React.useMemo(() => {
      let min = preFilteredRows.length
        ? new Date(preFilteredRows[0].values[id])
        : new Date(0);
      let max = preFilteredRows.length
        ? new Date(preFilteredRows[0].values[id])
        : new Date(0);

      preFilteredRows.forEach((row) => {
        const rowDate = new Date(row.values[id]);

        min = rowDate <= min ? rowDate : min;
        max = rowDate >= max ? rowDate : max;
      });

      return [min, max];
    }, [id, preFilteredRows]);

    return (
      <div>
        <input
          min={min.toISOString().slice(0, 10)}
          onChange={(e) => {
            const val = e.target.value;
            setFilter((old = []) => [val ? val : undefined, old[1]]);
          }}
          type="date"
          style={{
            width: "100px",
            marginRight: "0.5rem",
            marginBottom: "2.2rem",
          }}
          value={filterValue[0] || ""}
        />
        {/* {" a "} */}
        <input
          max={max.toISOString().slice(0, 10)}
          onChange={(e) => {
            const val = e.target.value;
            setFilter((old = []) => [
              old[0],
              val ? val.concat("T23:59:59.999Z") : undefined,
            ]);
          }}
          type="date"
          style={{
            width: "100px",
            marginRight: "0.5rem",
            marginBottom: "2.2rem",
          }}
          value={filterValue[1]?.slice(0, 10) || ""}
        />
      </div>
    );
  }

  const consultaComandapagados = () => {
    getComandapagados().then((datos) => {
      setComandapagados({
        data: datos,
        loading: false,
      });
    });
  };

  const modifComandapagado = (id) => {
    let id_comandapagado = id;
    getComandapagadoId(id_comandapagado).then((resp) => {
      console.log(resp);
      setComandapagado(resp);
      // alert("Comanda Modificada")

      handleShow();
    });
  };

  // LOGISTICA
  const modificaComanda = (nrodecomanda) => {
    const buscacomanda = data.filter(function (element) {
      return element.nrodecomanda === nrodecomanda;
    });
    setComandapagado(buscacomanda);
    handleShow();
  };

  // ASIGNAR MASIVO
  // const asignaRutaMasivo = (idruta, idcomanda) => {
  //   const buscacomanda = data.filter(function (element) {
  //     return element.nrodecomanda === idcomanda;
  //   });

  //   if (buscacomanda[0].codestado._id === "62200265c811f41820d8bda9") {
  //     const buscaRuta = data.filter(function (element) {
  //       return (
  //         element.codcli.ruta._id === idruta &&
  //         element.codestado._id === "62200265c811f41820d8bda9"
  //       );
  //     });
  //     setComanda(buscaRuta);
  //     handleShowMasivo();
  //   } else {
  //     alert("La asignacion masiva de rutas deben tener como estado A Preparar");
  //   }
  // };

  const handleClose = () => {
    setShow(false);
    consultaComandapagados();
  };

  const handleCloseMasivo = () => {
    setShowMasivo(false);
    consultaComandapagados();
  };

  // HANDLE SHOWS
  const handleShow = () => setShow(true);

  const handleShowMasivo = () => setShowMasivo(true);




 

  
  const columns = [

    {
      sticky: "left",
      Header: "Pago Cuota",
      width: 50,
      // accessor: "modifica",
      Cell: (row) => (
        <div style={{ textAlign: "center" }}>
          <button
            id="acepto"
            className="btn btn-primary"
            onClick={(e) => modifComandapagado(row.row.original._id)}
          >
            <i
              className="fa fa-pencil-square-o"
              aria-hidden="true"
              color="white"
            ></i>
          </button>
          {/* <GetDataInvoiceAdmin datacomanda={row.row.original.nrodecomanda} /> */}
        </div>
      ),
    },

    // {
    //   Header: "Modifica",
    //   width: "70",

    //   Cell: (row) => (
    //     <div style={{ textAlign: "center" }}>
    //       <button
    //         id="acepto"
    //         className="btn btn-primary"
    //         onClick={(e) => modificaComanda(row.row.original.nrodecomanda)}
    //       >
    //         <i
    //           className="fa fa-pencil-square-o"
    //           aria-hidden="true"
    //           color="white"
    //         ></i>
    //       </button>
    //     </div>
    //   ),
    // },
  
    // {
    //   Header: "Eliminar",
    //   width: "70",

    //   Cell: (row) => (
    //     <div style={{ textAlign: "center" }}>
    //       <button
    //         id="acepto"
    //         className="btn btn-danger"
    //         onClick={(e) => deleteComanda(row.row.original.nrodecomanda)}
    //       >
    //         <i className="fa fa-trash-o" aria-hidden="true" color="white"></i>
    //       </button>
    //     </div>
    //   ),
    // },
  
  
    {
      sticky: "left",
      id: "nrocomp",
      Header: "Nro",
      width: "60",
      accessor: "nrocomp",
      filter: "equals",
      Cell: (row) => <div style={{ textAlign: "center" }}>{row.value}</div>,
    },

    {
      Header: "Apellido",
      accessor: "codalu.apellido",
      width: "150",
      sticky: "left",
    },

    {
      Header: "Nombre",
      accessor: "codalu.nombre",
      width: "150",
      sticky: "left",
    },

    // {
    //   Header: "Producto",
    //   accessor: "codprod.descripcion",
    // },

    {
      id: "fecha",
      width: "110",
      Header: "Fecha Comanda",
      style: { marginBottom: "2rem" },
      he: "200rem",
      accessor: (d) => `${d.fechapago.slice(0, 10)}`,

      Cell: (row) => (
        <div style={{ textAlign: "center" }}>{row.value.slice(0, 10)}</div>
      ),

      Filter: DateRangeColumnFilter,
      filter: dateBetweenFilterFn,
    },
    
    // {
    //   Header: "Cantidad",
    //   accessor: "cantidad",
    //   width: "80",
    //   filter: "equals",
    //   Footer: (info) => {
    //     const total = React.useMemo(
    //       () => info.rows.reduce((sum, row) => row.values.cantidad + sum, 0),
    //       [info.rows]
    //     );
    //     return (
    //       <>
    //         <div style={{ textAlign: "center" }}>
    //           <b className="pie">{total}</b>{" "}
    //         </div>
    //       </>
    //     );
    //   },

    //   Cell: (row) => <div style={{ textAlign: "center" }}>{row.value}</div>,
    // },

    // {
    //   Header: "Cant Ent",
    //   accessor: "cantidadentregada",
    //   width: "80",

    //   filter: "equals",
    //   Footer: (info) => {
    //     const total = React.useMemo(
    //       () =>
    //         info.rows.reduce(
    //           (sum, row) => row.values.cantidadentregada + sum,
    //           0
    //         ),
    //       [info.rows]
    //     );
    //     return (
    //       <>
    //         <div style={{ textAlign: "center" }}>
    //           <b className="pie1">{total}</b>{" "}
    //         </div>
    //       </>
    //     );
    //   },

    //   Cell: (row) => <div style={{ textAlign: "center" }}>{row.value}</div>,
    // },

    // {
    //   Header: "Lista",
    //   accessor: "lista.lista",
    //   width: "80",
    // },

    // {
    //   Header: () => (
    //     <div
    //       style={{
    //         textAlign: "center",
    //       }}
    //     >
    //       Precio Unitario
    //     </div>
    //   ),
    //   accessor: "monto",
    //   width: "100",

    //   Cell: (row) => (
    //     <div style={{ textAlign: "right" }}>
    //       {new Intl.NumberFormat("es-AR", {
    //         style: "currency",
    //         currency: "ARS",
    //       }).format(row.value)}
    //     </div>
    //   ),
    // },

   
    {
      Header: () => (
        <div
          style={{
            textAlign: "center",
          }}
        >
          Monto Comanda
        </div>
      ),

      accessor: "montoaplicado",
      width: "130",
      id: "montoaplicado",

      Cell: (props) =>
        new Intl.NumberFormat("es-AR", {
          style: "currency", 
          currency: "ARS",
        }).format(props.value),

      Footer: (info) => {
        // Only calculate total visits if rows change
        const montoaplicado = React.useMemo(
          () => info.rows.reduce((sum, row) => row.values.montoaplicado + sum, 0),
          [info.rows]
        );

        return (
          <div className="pie" style={{ textAlign: "right", color: "blue" }}>
            {new Intl.NumberFormat("es-AR", {
              style: "currency",
              currency: "ARS",

            }).format(montoaplicado)}
          </div>
        );
      },

      Cell: (row) => (
        <div style={{ textAlign: "right", color: "blue" }}>
          {row.value !== 1
            ? new Intl.NumberFormat("es-AR", {
                style: "currency",
                currency: "ARS",
              }).format(row.value)
            : null /* O puedes reemplazar null por un string vacío */}
        </div>
      ),


    },


    {
      Header: () => (
        <div
          style={{
            textAlign: "center",
          }}
        >
          Monto Pagado
        </div>
      ),

      accessor: "montoaplicadop",
      width: "130",
      id: "montoaplicadop",

      Cell: (props) =>
        new Intl.NumberFormat("es-AR", {
          style: "currency", 
          currency: "ARS",
        }).format(props.value),

      Footer: (info) => {
        // Only calculate total visits if rows change
        const montoaplicadop = React.useMemo(
          () => info.rows.reduce((sum, row) => row.values.montoaplicadop + sum, 0),
          [info.rows]
        );

        return (
          <div className="pie" style={{ textAlign: "right", color: "blue" }}>
            {new Intl.NumberFormat("es-AR", {
              style: "currency",
              currency: "ARS",

            }).format(montoaplicadop)}
          </div>
        );
      },

      Cell: (row) => (
        <div style={{ textAlign: "right", color: "blue" }}>
          {row.value !== 0
            ? new Intl.NumberFormat("es-AR", {
                style: "currency",
                currency: "ARS",
              }).format(row.value)
            : null /* O puedes reemplazar null por un string vacío */}
        </div>
      ),

      // Cell: (row) => (
      //   <div style={{ textAlign: "right", color: "blue"}}>
      //     {new Intl.NumberFormat("es-AR", {
      //       style: "currency",
      //       currency: "ARS",
      //     }).format(row.value)}
      //   </div>
      // ),

      // Cell: (row) => <div style={{ textAlign: "right" }}>{row.value}</div>,
    },

    {
      Header: () => (
        <div
          style={{
            textAlign: "center",
          }}
        >
          Saldo por Pagar
        </div>
      ),
    
      accessor: "saldo", // Accesor personalizado para saldo
      width: "130",

      Cell: (props) => {
        const montoaplicado = props.row.values.montoaplicado;
        const montoaplicadop = props.row.values.montoaplicadop;
        const saldo = montoaplicado - montoaplicadop;
    
        return ( 
          <div className="" style={{ textAlign: "right", color: "red" }}>
           {new Intl.NumberFormat("es-AR", {
              style: "currency",
              currency: "ARS",
            }).format(saldo)}
          </div>
      );
      },
    
      Footer: (info) => {
        // Cálculo del saldo total
        const totalSaldo = React.useMemo(
          () =>
            info.rows.reduce(
              (sum, row) => row.values.montoaplicado - row.values.montoaplicadop + sum,
              0
            ),
          [info.rows]
        );
    
        return (
          <div className="pie" style={{ textAlign: "right", color: "red" }}>
            {new Intl.NumberFormat("es-AR", {
              style: "currency",
              currency: "ARS",
            }).format(totalSaldo)}
          </div>
        );
      },
    
      // Cell: (row) => (
      //   <div style={{ textAlign: "right", color: "green" }}>
      //     {new Intl.NumberFormat("es-AR", {
      //       style: "currency",
      //       currency: "ARS",
      //     }).format(row.value)}
      //   </div>
      // ),
    },


    // {
    //   Header: "Total Ent",
    //   id: "totalentregada",
    //   accessor: (d) => `${d.cantidadentregada}` * `${d.monto}`,
    //   width: "150",

    //   Cell: (props) =>
    //     new Intl.NumberFormat("es-AR", {
    //       style: "currency",
    //       currency: "ARS",
    //     }).format(props.value),

    //   Footer: (info) => {
    //     const totalentregada = React.useMemo(
    //       () =>
    //         info.rows.reduce((sum, row) => row.values.totalentregada + sum, 0),
    //       [info.rows]
    //     );

    //     return (
    //       <div className= "pie1" style={{ textAlign: "right" }}>
    //         {new Intl.NumberFormat("es-AR", {
    //           style: "currency",
    //           currency: "ARS",
    //           color: "red",
    //         }).format(totalentregada)}
    //       </div>
    //     );
    //   },

    //   Cell: (row) => (
    //     <div style={{ textAlign: "right" }}>
    //       {new Intl.NumberFormat("es-AR", {
    //         style: "currency",
    //         currency: "ARS",
    //         color: "red",
    //       }).format(row.value)}
    //     </div>
    //   ),
    // },

    // {
    //   Header: "Estado",
    //   id: "Estado",
    //   accessor: "codestado.estado",
    //   width: "100",
    // },

    // {
    //   Header: "Ruta",
    //   accessor: "codcli.ruta.ruta",
    //   width: "100",
    // },

    // {
    //   Header: "Camionero",
    //   accessor: "camionero.nombres",
    //   width: "100",
    // },

    // {
    //   Header: "Punto Dist",
    //   accessor: "camion.camion",
    //   width: "100",
    // },

    // {
    //   Header: "Usuario",
    //   accessor: "usuario.nombres",
    //   width: "100",
    // },

    // {
    //   Header: "A. Masivo",
    //   width: "50",
    //   Cell: (row) => (
    //     <div style={{ textAlign: "center" }}>
    //       <div class="bootstrap-switch-square">
    //         <input onClick={(e) => {e.target.checked ? AddArrayComandas(e, row.row.original.nrodecomanda, row.row.original.codestado._id) : DelArrayComandas(e, row.row.original.nrodecomanda)}} 
    //         type="checkbox" data-toggle="switch" name="Resend" id="Resend2"  />
    //       </div>
    //     </div>
    //   ),
    // },

    // {
    //   Header: "Asignar",
    //   width: "70",

    //   Cell: (row) => (
    //     <div style={{ textAlign: "center" }}>
    //       <button
    //         id="acepto"
    //         className="btn btn-dark"
    //         onClick={(e) =>
    //           asignaRutaMasivo(
    //             row.row.original.codcli.ruta._id,
    //             row.row.original.nrodecomanda,
    //           )
    //         }
    //       >
    //         <i className="fa fa-truck" aria-hidden="true" color="white"></i>
    //       </button>
    //     </div>
    //   ),
    // },

//    {
//      Header: "Imprimir",
//      width: "70",
//      Cell: (row) => (
//        <div style={{ textAlign: "center" }}>
//           <GetDataInvoiceAdmin datacomanda={row.row.original.nrodecomanda} datacodcli={row.row.original.codcli._id} />
//        </div>
//      ),
//    },


  ];

  const today = new Date();
  const numberOfDaysToAdd = 0;
  const date = today.setDate(today.getDate() + numberOfDaysToAdd);
  const fechaHoy = new Date(date).toISOString().split('T')[0]

  return (
    <>
      {!showDatesRange &&
        <form>
          <div className="row justify-content-center">
            <div className="col-6">
              <h3 className="mt-3">INGRESE PERIODO - 
                Desde: <input 
                        type="date" 
                        name="fdesde" 
                        id="inputdesde"
                        defaultValue={fechaHoy} 
                        required 
                        /> -
                Hasta: <input 
                        type="date" 
                        name="fhasta" 
                        id="inputhasta" 
                        defaultValue={fechaHoy} 
                        required/>
              </h3>
            </div>
            <div className="col-2">
              <button 
                className="btn btn-danger ml-5 mt-2 mb-2 justify-content-end" 
                onClick={() => getRangeFechas()}> Aceptar </button>
              </div>
          </div>  
        </form>
      }

      {showDatesRange &&
        <>
          {!carga && <h2 className="cargando mt-3 ml-1"> Cargando... Espere...</h2>}
          <div className="row justify-content-center">
            <div className="col-4">
              <h2 className="mt-2">COMANDAS DEL: {fechaDesde}  -  AL: {fechaHasta}</h2>
            </div>
            {/* <div className="col-2">
              <button
                id="asignarmasivo"
                className="btn btn-danger ml-5 mt-2 mb-2 justify-content-end"
                onClick={(e) => masivoCierreComandas(arrayComandas) }
              >
              Cambio Estado Masivo
              </button>
            </div> */}
           <div className="col-2">
              <button          
                className="btn btn-primary mr-5 mt-2 mb-2 justify-content-end"    
                onClick={() => window.location.reload()}
              >
                Recargar Pagina...
              </button>
            </div>
          </div>
          <Styles className="container table sticky" style={{ width: "auto", height: 400 }}>
            <div className="App">
              <Table columns={columns} data={data} />
            </div>
            <ModalComandapagado show={show} handleClose={handleClose} comandapagado={comandapagado} />
            {/* <ModalAsignar
              show={showMasivo}
              handleClose={handleCloseMasivo}
              comanda={comanda}
            /> */}
          </Styles>
         </>
      }
    </>
  );
}

export default AppComandaPReactTable;
