import React, { useState, useEffect } from "react";
import {
  addAlumno,
  getAlumno,
  delAlumno,
  getAlumnoId,
} from "../helpers/rutaAlumnos";
import { getSalas } from "../helpers/rutaSalas";
import { Modal, Button } from "react-bootstrap";
import '../css/modalformalert.css'; // Estilos personalizados
import { FaThumbsUp } from "react-icons/fa"; // Importar el ícono de pulgar hacia arriba



//import { getLocalidades } from "../helpers/rutaLocalidades";
//import { getRutas } from "../helpers/rutaRutas";
//import { getIva } from "../helpers/rutaIva";


//import "../css/addAlumnoform.css";

const AddAlumnoForm = ({ setShow }) => {
 // const id = JSON.parse(localStorage.getItem("id"));
  const [formValues, setFormValues] = useState({
    codalu: "",
    apellido: "",
    nombre: "",
    dni: "",
    dnipapa: "",
    dnimama: "",
    fecnac: "",
    domicilio: "",
    emailmama: "",
    telmama: "",
    apemama: "",
    emailpapa: "",
    telpapa: "",
    apepapa: "",
    sede: "",
    ocumama: "",
    ocupapa: "",
    alergia: "",
    observa: "",
    retira: "",
    autofo: "false",
    autopa: "false",
    actana: "false",
    fotodni: "false",
    fotodnipa: "false",
    fotodnima: "false",
    carnet: "false",
    certifi: "false",
    entre: "false",
    regla: "false",
    horajar: "",
    sala: "",
  //  usuario: id,
  });

  const [showSuccessModal, setShowSuccessModal] = useState(false);

  const handleChange = (e) => {
    setFormValues({
      ...formValues,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    addAlumno(formValues).then((resp) => {
      console.log(resp);
      setFormValues({
        codalu: "",
        apellido: "",
        nombre: "",
        dni: "",
        dnipapa: "",
        dnimama: "",
        fecnac: "",
        domicilio: "",
        emailmama: "",
        telmama: "",
        apemama: "",
        emailpapa: "",
        telpapa: "",
        apepapa: "",
        sede: "",
        ocumama: "",
        ocupapa: "",
        alergia: "",
        observa: "",
        retira: "",
        autofo: "false",
        autopa: "false",
        actana: "false",
        fotodni: "false",
        fotodnipa: "false",
        fotodnima: "false",
        carnet: "false",
        certifi: "false",
        entre: "false",
        regla: "false",
        horajar: "",
        sala: "",
        //usuario: id,

      });
      setShowSuccessModal(true);  // Mostrar modal de éxito
      // alert("Grabado Exitoso !!!")
      //   setShow(false);
    });
  };

  const handleSuccessClose = () => {
    setShowSuccessModal(false);
      setShow(false);
    // handleClose();  // Cerrar modal principal después del éxito
  };
  
  const [salas, setSalas] = useState({
    data: {},
    loading: true,
  });
  useEffect(() => {
    getSalas().then((salas) => {
      setSalas({
        data: salas,
        loading: false,
      });
    });
  }, []);
  

  /*
  const [iva, setIva] = useState({
    data: {},
    loading: true,
  });
  useEffect(() => {
    getIva().then((iva) => {
      setIva({
        data: iva,
        loading: false,
      });
    });
  }, []);
  */

  /*
  const [rutas, setRutas] = useState({
    data: {},
    loading: true,
  });
  useEffect(() => {
    getRutas().then((rutas) => {
      setRutas({
        data: rutas,
        loading: false,
      });
    });
  }, []);
  */

  return (
    <>
      {!salas.loading &&  (
        <div className="container">
          {/* {localidades.data.localidades.map((localidad) => (
            <h3>{localidad.localidad}</h3>
          ))} */}

          <form onSubmit={handleSubmit}>
            <div className="form-row">
              <div className="form-group mt-3 col-sm-4">
                <label className="">Apellido del Alumno</label>
                <input
                  type="text"
                  className="form-control"
                  name="apellido"
                  maxLength="50"
                  required
                  value={formValues.apellido}
                  onChange={handleChange}
                />
                {console.log(formValues.apellido)}
              </div>

              <div className="form-group mt-3 col-sm-4">
                <label className="">Nombre del Alumno</label>
                <input
                  type="text"
                  className="form-control"
                  name="nombre"
                  maxLength="50"
                  required
                  value={formValues.nombre}
                  onChange={handleChange}
                />
                {console.log(formValues.nombre)}
              </div>

              <div className="form-group mt-3 col-sm-2">
                <label className="">DNI del Alumno</label>
                <input
                  type="text"
                  className="form-control"
                  name="dni"
                  maxLength="8"
                  required
                  value={formValues.dni}
                  onChange={handleChange}
                />
                {console.log(formValues.dni)}
                </div>

              <div className="form-group col-sm-2">
                <label className="mt-4">Fecha Nac. Alumno</label>
                <input
                  type="date"
                  className="form-control"
                  name="fecnac"
                  id="fecnac"
                  maxLength="30"
                  required
                  value={formValues.fecnac}
                  onChange={handleChange}
                />
                {console.log(formValues.fecnac)}
              </div>

              <div className="form-group mt-3 col-sm-4">
                <label className="">Domicilio</label>
                <input
                  type="text"
                  className="form-control"
                  name="domicilio"
                  maxLength="50"
                  required
                  value={formValues.domicilio}
                  onChange={handleChange}
                />
                {console.log(formValues.domicilio)}
              </div>

              <div className="form-group mt-3 col-sm-2">
                  <label className="">Sede</label>
                  <select
                    className="form-control"
                    name="sede"
                    value={formValues.sede}
                    onChange={handleChange}
                    required
                  >
                    <option selected value="">
                      Elija opción
                    </option>
                    <option>Sede Norte</option>
                    <option>Sede Sur</option>
                  </select>
                </div>

              <div className="form-group mt-3 col-sm-2">
                <label className="">Horario Jardin</label>
                <input
                  type="text"
                  className="form-control"
                  name="horajar"
                  maxLength="50"
                  //required
                  value={formValues.horajar}
                  onChange={handleChange}
                />
                {console.log(formValues.horajar)}
              </div>

              <div className="form-group mt-3 col-sm-2">
                <label className="">DNI Mama</label>
                <input
                  type="text"
                  className="form-control"
                  name="dnimama"
                  maxLength="50"
                  //required
                  value={formValues.dnimama}
                  onChange={handleChange}
                />
                {console.log(formValues.dnimama)}
              </div>

              <div className="form-group mt-3 col-sm-2">
                <label className="">DNI Papa</label>
                <input
                  type="text"
                  className="form-control"
                  name="dnipapa"
                  maxLength="50"
                  //required
                  value={formValues.dnipapa}
                  onChange={handleChange}
                />
                {console.log(formValues.dnipapa)}
              </div>
            </div>

            <div className="form-row">
              <div className="form-group mt-3 col-sm-4">
                <label className="">Apellido y Nombre Mama</label>
                <input
                  type="text"
                  className="form-control"
                  name="apemama"
                  maxLength="50"
                  //required
                  value={formValues.apemama}
                  onChange={handleChange}
                />
                {console.log(formValues.apemama)}
              </div>

              <div className="form-group mt-3 col-sm-3">
                <label className="">Email Mama</label>
                <input
                  type="email"
                  className="form-control"
                  name="emailmama"
                  maxLength="50"
                  //required
                  value={formValues.emailmama}
                  onChange={handleChange}
                />
                {console.log(formValues.emailmama)}
              </div>

              <div className="form-group mt-3 col-sm-2">
                <label className="">Telefono Mama</label>
                <input
                  type="text"
                  className="form-control"
                  name="telmama"
                  maxLength="50"
                  //required
                  value={formValues.telmama}
                  onChange={handleChange}
                />
                {console.log(formValues.telmama)}
              </div>

              <div className="form-group mt-3 col-sm-3">
                <label className="">Ocupacion Mama</label>
                <input
                  type="text"
                  className="form-control"
                  name="ocumama"
                  maxLength="50"
                  //required
                  value={formValues.ocumama}
                  onChange={handleChange}
                />
                {console.log(formValues.ocumama)}
              </div>

            </div>

            <div className="form-row">
              <div className="form-group mt-3 col-sm-4">
                <label className="">Apellido y Nombre Papa</label>
                <input
                  type="text"
                  className="form-control"
                  name="apepapa"
                  maxLength="50"
                  //required
                  value={formValues.apepapa}
                  onChange={handleChange}
                />
                {console.log(formValues.apepapa)}
              </div>

              <div className="form-group mt-3 col-sm-3">
                <label className="">Email Papa</label>
                <input
                  type="email"
                  className="form-control"
                  name="emailpapa"
                  maxLength="50"
                  //required
                  value={formValues.emailpapa}
                  onChange={handleChange}
                />
                {console.log(formValues.emailpapa)}
              </div>

              <div className="form-group mt-3 col-sm-2">
                <label className="">Telefono Papa</label>
                <input
                  type="text"
                  className="form-control"
                  name="telpapa"
                  maxLength="50"
                  //required
                  value={formValues.telpapa}
                  onChange={handleChange}
                />
                {console.log(formValues.telpapa)}
              </div>

              <div className="form-group mt-3 col-sm-3">
                <label className="">Ocupacion Papa</label>
                <input
                  type="text"
                  className="form-control"
                  name="ocupapa"
                  maxLength="50"
                  //required
                  value={formValues.ocupapa}
                  onChange={handleChange}
                />
                {console.log(formValues.ocupapa)}
              </div>

            </div>
          
            <div className="form-row">
              <div className="form-group mt-3 col-sm-3">
                <label className="">Alergias</label>
                  <input
                    type="text"
                    className="form-control"
                    name="alergia"
                    maxLength="50"
                    //required
                    value={formValues.alergia}
                    onChange={handleChange}
                  />
                  {console.log(formValues.alergia)}
              </div>

              <div className="form-group mt-3 col-sm-4">
                <label className="">Observaciones</label>
                  <input
                    type="text"
                    className="form-control"
                    name="observa"
                    maxLength="50"
                    //required
                    value={formValues.observa}
                    onChange={handleChange}
                  />
                  {console.log(formValues.observa)}
              </div>

              <div className="form-group mt-3 col-sm-3">
                <label className="">Quien Retira</label>
                  <input
                    type="text"
                    className="form-control"
                    name="retira"
                    maxLength="50"
                    //required
                    value={formValues.retira}
                    onChange={handleChange}
                  />
                  {console.log(formValues.retira)}
              </div>

              <div className="form-group mt-3 col-sm-2">
                <label className="">Sala</label>
                <select
                  className="form-control"
                  name="sala"
                  value={formValues.sala}
                  onChange={handleChange}
                  required
                >
                  <option selected value="">
                    Elija opción
                  </option>
                  {salas.data.salas.map((sala) => (
                    <option value={sala._id}>{sala.sala}-{sala.turno}</option>
                  ))}
                </select>
              </div>

            </div>

            <div className="form-row mt-5">
              <div className="form-check">
                <label className="form-check-label ml-5 mr-5 mb-5" for="defaultCheck1">
                  Autorizacion Fotos
                </label>
                <input className="form-check-input mr-5" 
                      name="autofo"
                      type="checkbox" 
                      value={1}
                      onChange={handleChange}
                      id="defaultCheck1"
                />
                <label className="form-check-label ml-5 mr-5 mb-5" for="defaultCheck1">
                  Autorizacion Cambio Pañales
                </label>
                <input className="form-check-input" 
                      name="autopa"
                      type="checkbox" 
                      value={1}
                      onChange={handleChange}
                      id="defaultCheck1"
                />
                <label className="form-check-label ml-5 mr-5 mb-5" for="defaultCheck1">
                  Acta de Nacimiento
                </label>
                <input className="form-check-input" 
                      name="actana"
                      type="checkbox" 
                      value={1}
                      onChange={handleChange}
                      id="defaultCheck1"
                />
                <label className="form-check-label ml-5 mr-5 mb-5" for="defaultCheck1">
                  Fotocopia DNI
                </label>
                <input className="form-check-input" 
                      name="fotodni"
                      type="checkbox" 
                      value={1}
                      onChange={handleChange}
                      id="defaultCheck1"
                />
                <label className="form-check-label ml-5 mr-5 mb-5" for="defaultCheck1">
                  Fototocopia DNI Mama
                </label>
                <input className="form-check-input" 
                      name="fotodnima"
                      type="checkbox" 
                      value={1}
                      onChange={handleChange}
                      id="defaultCheck1"
                />
                <label className="form-check-label ml-5 mr-5 mb-5" for="defaultCheck1">
                  Fotocopia DNI Papa
                </label>
                <input className="form-check-input" 
                      name="fotodnipa"
                      type="checkbox" 
                      value={1}
                      onChange={handleChange}
                      id="defaultCheck1"
                />
                <label className="form-check-label ml-5 mr-5 mb-5" for="defaultCheck1">
                  Carnet Vacunacion
                </label>
                <input className="form-check-input mr-5" 
                      name="carnet"
                      type="checkbox" 
                      value={1}
                      onChange={handleChange}
                      id="defaultCheck1"
                />
                <label className="form-check-label ml-5 mr-5 mb-5" for="defaultCheck1">
                  Certificado Actitud Fisica
                </label>
                <input className="form-check-input" 
                      name="certifi"
                      type="checkbox" 
                      value={1}
                      onChange={handleChange}
                      id="defaultCheck1"
                />
                <label className="form-check-label ml-5 mr-5 mb-5" for="defaultCheck1">
                  Entrevista
                </label>
                <input className="form-check-input" 
                      name="entre"
                      type="checkbox" 
                      value={1}
                      onChange={handleChange}
                      id="defaultCheck1"
                />
                <label className="form-check-label ml-5 mr-5 mb-5" for="defaultCheck1">
                  Reglamento Firmado
                </label>
                <input className="form-check-input" 
                      name="regla"
                      type="checkbox" 
                      value={1}
                      onChange={handleChange}
                      id="defaultCheck1"
                />
              </div>

            </div>

            <div>
              <button
                type="submit"
                className="btn btn-dark mt-5 mb-3"
                id="button"
                // onClick={() => window.location.reload()}
              >
                Guardar
                
              </button>
              
            </div>
          </form>
        </div>
      )}

      {/* Modal de éxito estilo imagen */}
      <Modal show={showSuccessModal} onHide={handleSuccessClose} centered>
        <div className="custom-modal">
          <div className="custom-modal-icon">
            <FaThumbsUp /> {/* Manito de "OK" */}
          </div>
          <Modal.Body className="custom-modal-body">
            <h4>Info</h4>
            <p>¡Registro agregado correctamente!</p>
          </Modal.Body>
          <Button className="custom-modal-button" onClick={handleSuccessClose}>
            Aceptar
          </Button>
        </div>
      </Modal>

    </>
  );
};

export default AddAlumnoForm;


