import React from "react";
import "../css/footer.css";
import "bootstrap/dist/css/bootstrap.min.css";
import logo from "../images/Tiku-02.png";

const Footer = () => {
  return (
    <footer id="footer">
      <div className="container">
        <hr className="mt-1"/>
        <div className="row">
          <div className="col-lg-4 col-xs-12 col-md-12">
            <div className="col-lg-6 col-md-6 visible-md visible-lg copyright">
              <img src={logo} alt="logo" /> 
              
            </div>
            </div>
              <div className="col-4">
            </div>
          <div id="contacto" className="col-lg-4 col-xs-12 col-md-12">
            <p className="contact"><strong>Vesion 1.0</strong></p>
            <p className="contact"><strong>by Stairway Net</strong></p>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
