import React, { useState, useEffect } from "react";
import { modifLista } from "../helpers/rutaListas";
import { Modal, Button } from "react-bootstrap";
import { getListas } from "../helpers/rutaListas";
import { getGrupos } from "../helpers/rutaGrupos";
import '../css/modalformalert.css'; // Estilos personalizados
import { FaThumbsUp } from "react-icons/fa"; // Importar el ícono de pulgar hacia arriba


const ModalFormLista = ({ lista, handleClose }) => {
  console.log(lista);
  const id = localStorage.getItem("id");
  const [formValues, setFormValues] = useState({
    codlista: lista.listas.codlista,
    lista: lista.listas.lista,
    
    // usuario: id,
  });

  const [showSuccessModal, setShowSuccessModal] = useState(false);

  const handleChange = (e) => {
    setFormValues({
      ...formValues,
      [e.target.name]: e.target.value,
    });
  };
  const handleSubmit = (e) => {
    console.log(lista.listas._id);
    e.preventDefault();
    modifLista(formValues, lista.listas._id).then((respuesta) => {
      setShowSuccessModal(true);  // Mostrar modal de éxito
    });
  };

  const handleSuccessClose = () => {
    setShowSuccessModal(false);
    handleClose();  // Cerrar modal principal después del éxito
  };



  const [listas, setListas] = useState({
    data: {},
    loading: true,
  });
  useEffect(() => {
    getListas().then((listas) => {
      setListas({
        data: listas,
        loading: false,
      });
    });
  }, []);

  const [grupos, setGrupos] = useState({
    data: {},
    loading: true,
  });
  useEffect(() => {
    getGrupos().then((grupos) => {
      setGrupos({
        data: grupos,
        loading: false,
      });
    });
  }, []);

  return (
    <>
      {!listas.loading && !grupos.loading && (
        <form onSubmit={handleSubmit}>
          <Modal.Body>

          <div className="form-group mt-3">
              <label>Cod Mes</label>
              <input
                rows="1"
                type="text"
                className="form-control"
                name="codlista"
                maxLength="50"
                minLength="5"
                required
                value={formValues.codlista}
                onChange={handleChange}
              />
            </div>

            <div className="form-group mt-3">
              <label>Mes/Programa</label>
              <input
                rows="1"
                type="text"
                className="form-control"
                name="lista"
                maxLength="50"
                minLength="5"
                required
                value={formValues.lista}
                onChange={handleChange}
              />
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button type="submit" variant="dark">
              Guardar
            </Button>
          </Modal.Footer>
        </form>
      )}
      ;

      {/* Modal de éxito estilo imagen */}
      <Modal show={showSuccessModal} onHide={handleSuccessClose} centered>
        <div className="custom-modal">
          <div className="custom-modal-icon">
            <FaThumbsUp /> {/* Manito de "OK" */}
          </div>
          <Modal.Body className="custom-modal-body">
            <h4>Info</h4>
            <p>¡Los cambios se guardaron correctamente!</p>
          </Modal.Body>
          <Button className="custom-modal-button" onClick={handleSuccessClose}>
            Aceptar
          </Button>
        </div>
      </Modal>
    </>
  );
};

export default ModalFormLista;
