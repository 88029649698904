import React, { useState, useEffect } from "react";
import { modifGrupo } from "../helpers/rutaGrupos";
import { Modal, Button } from "react-bootstrap";
import { getGrupos } from "../helpers/rutaGrupos";
import '../css/modalformalert.css'; // Estilos personalizados
import { FaThumbsUp } from "react-icons/fa"; // Importar el ícono de pulgar hacia arriba


const ModalFormGrupo = ({ grupo, handleClose }) => {
  const [formValues, setFormValues] = useState({
    grupo: grupo.grupos.grupo,
  });

  const [showSuccessModal, setShowSuccessModal] = useState(false);

  const handleChange = (e) => {
    setFormValues({
      ...formValues,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    modifGrupo(formValues, grupo.grupos._id).then((respuesta) => {
      setShowSuccessModal(true);  // Mostrar modal de éxito
    });
  };

  const handleSuccessClose = () => {
    setShowSuccessModal(false);
    handleClose();  // Cerrar modal principal después del éxito
  };

  return (
    <>
      <form onSubmit={handleSubmit}>
        <Modal.Body>
          <div className="form-group mt-3">
            <label>Grupo</label>
            <input
              type="text"
              className="form-control"
              name="grupo"
              maxLength="50"
              minLength="5"
              required
              value={formValues.grupo}
              onChange={handleChange}
            />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button type="submit" variant="dark">
            Guardar
          </Button>
        </Modal.Footer>
      </form>

      {/* Modal de éxito estilo imagen */}
      <Modal show={showSuccessModal} onHide={handleSuccessClose} centered>
        <div className="custom-modal">
          <div className="custom-modal-icon">
            <FaThumbsUp /> {/* Manito de "OK" */}
          </div>
          <Modal.Body className="custom-modal-body">
            <h4>Info</h4>
            <p>¡Los cambios se guardaron correctamente!</p>
          </Modal.Body>
          <Button className="custom-modal-button" onClick={handleSuccessClose}>
            Aceptar
          </Button>
        </div>
      </Modal>
    </>
  );
};

export default ModalFormGrupo;



// import React, { useState, useEffect } from "react";
// import { modifGrupo } from "../helpers/rutaGrupos";
// import { Modal, Button } from "react-bootstrap";
// import { getGrupos } from "../helpers/rutaGrupos";

// const ModalFormGrupo = ({ grupo, handleClose }) => {
//   console.log(grupo);
//   const id = localStorage.getItem("id");
//   const [formValues, setFormValues] = useState({
//     grupo:grupo.grupos.grupo,
//   });

//   const handleChange = (e) => {
//     setFormValues({
//       ...formValues,
//       [e.target.name]: e.target.value,
//     });
//   };
//   const handleSubmit = (e) => {
//     console.log(grupo.grupos._id);
//     e.preventDefault();
//     modifGrupo(formValues, grupo.grupos._id).then((respuesta) => {
//       console.log(respuesta);
//       alert("Cambio Exitoso !!!")
//       handleClose();
//     });
//   };

//   const [grupos, setGrupos] = useState({
//     data: {},
//     loading: true,
//   });
//   useEffect(() => {
//     getGrupos().then((grupos) => {
//       setGrupos({
//         data: grupos,
//         loading: false,
//       });
//     });
//   }, []);

//   // const [grupos, setGrupos] = useState({
//   //   data: {},
//   //   loading: true,
//   // });
//   // useEffect(() => {
//   //   getGrupos().then((grupos) => {
//   //     setGrupos({
//   //       data: grupos,
//   //       loading: false,
//   //     });
//   //   });
//   // }, []);

//   return (
//     <>
//       {!grupos.loading && (
//         <form onSubmit={handleSubmit}>
//           <Modal.Body>
//             <div className="form-group mt-3">
//               <label>Grupo</label>
//               <input
//                 rows="1"
//                 type="text"
//                 className="form-control"
//                 name="grupo"
//                 maxLength="50"
//                 minLength="5"
//                 required
//                 value={formValues.grupo}
//                 onChange={handleChange}
//               />
//             </div>

//           </Modal.Body>
//           <Modal.Footer>
//             <Button type="submit" variant="dark">
//               Guardar
//             </Button>
//           </Modal.Footer>
//         </form>
//       )}
//       ;
//     </>
//   );
// };

// export default ModalFormGrupo;
