import React, { useState, useEffect } from "react";
import { addGasto, getGastos } from "../helpers/rutaGastos";
import { getProveedores } from "../helpers/rutaProveedores";
import { getProducservs } from "../helpers/rutaProducservs";
import { getUsuarios } from "../helpers/rutaUsuarios";
import { Modal, Button } from "react-bootstrap";
import '../css/modalformalert.css'; // Estilos personalizados
import { FaThumbsUp } from "react-icons/fa"; // Importar el ícono de pulgar hacia arriba

const AddGastoForm = ({ setShow }) => {
 // const id = JSON.parse(localStorage.getItem("id"));
  const [formValues, setFormValues] = useState({
    nrocomp: "",
    descripcion: "",
    codprov: "",
    fechacomp: "",
    monto: "",
    //codprod: "",
    montopagado: "",
    fechapago: "0",
    tipo: "",
    usuario: localStorage.getItem("id"),
    // usuario: usuario,
  });

  const [showSuccessModal, setShowSuccessModal] = useState(false);

  const handleChange = (e) => {
    setFormValues({
      ...formValues,
      [e.target.name]: e.target.value,
    });
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    addGasto(formValues).then((resp) => {
      console.log(resp);
      setFormValues({
        nrocomp: "",
        descripcion: "",
        codprov: "",
        fechacomp: "",
        monto: "",
        //codprod: "",
        montopagado: "0",
        fechapago: "",
        tipo: "",
        usuario: localStorage.getItem("id"),
        // usuario: usuario,

      });
      setShowSuccessModal(true);  // Mostrar modal de éxito
      // alert("Grabado Exitoso !!!")
      //   setShow(false);
    });
  };

  const handleSuccessClose = () => {
    setShowSuccessModal(false);
      setShow(false);
    // handleClose();  // Cerrar modal principal después del éxito
  };

  const [gastos, setGastos] = useState({
    data: {},
    loading: true,
  });
  useEffect(() => {
    getGastos().then((gastos) => {
      setGastos({
        data: gastos,
        loading: false,
      });
    });
  }, []);

  const [proveedores, setProveedores] = useState({
    data: {},
    loading: true,
  });
  useEffect(() => {
    getProveedores().then((proveedores) => {
      setProveedores({
        data: proveedores,
        loading: false,
      });
    });
  }, []);
 
  const [producservs, setProducservs] = useState({
    data: {},
    loading: true,
  });
  useEffect(() => {
    getProducservs().then((producservs) => {
      setProducservs({
        data: producservs,
        loading: false,
      });
    });
  }, []);

  const [usuarios, setUsuarios] = useState({
    data: {},
    loading: true,
  });
  useEffect(() => {
    getUsuarios().then((usuarios) => {
      setUsuarios({
        data: usuarios,
        loading: false,
      });
    });
  }, []);

  return (
    <>
      {!proveedores.loading && !producservs.loading && !usuarios.loading &&(
        <div className="container">
          <form onSubmit={handleSubmit}>
            <div className="form-row">
              <div className="form-group mt-3 col-sm-2">
                <label className="">Nro Comprob</label>
                <input
                  type="text"
                  className="form-control"
                  name="nrocomp"
                  maxLength="50"
                  required
                  value={formValues.nrocomp}
                  onChange={handleChange}
                />
                {console.log(formValues.nrocomp)}
              </div>

              <div className="form-group col-sm-2">
                <label className="mt-4">Fecha Comprob</label>
                <input
                  type="date"
                  className="form-control"
                  name="fechacomp"
                  id="fechacomp"
                  maxLength="30"
                  required
                  value={formValues.fechacomp}
                  onChange={handleChange}
                />
                {console.log(formValues.fechacomp)}
              </div>

              <div className="form-group mt-3 col-sm-2">
                <label className="">Sede</label>
                  <select
                    className="form-control"
                    name="tipo"
                    value={formValues.tipo}
                    onChange={handleChange}
                    required
                  >
                  <option selected value="">
                    Elija opción
                  </option>
                  <option>Sede Norte</option>
                  <option>Sede Sur</option>
                </select>
              </div>

              <div className="form-group mt-3 col-sm-4">
                <label className="">Proveedor</label>
                <select
                  className="form-control"
                  name="codprov"
                  value={formValues.codprov}
                  onChange={handleChange}
                  // required
                >
                  <option selected value="">
                    Elija opción
                  </option>
                  {proveedores.data.proveedores.map((proveedor) => (
                    <option value={proveedor._id}>{proveedor.razonsocial}</option>
                  ))}
                </select>
              </div>

              <div className="form-group mt-3 col-sm-2">
                <label className="">Monto</label>
                <input
                  type="text"
                  className="form-control"
                  name="monto"
                  maxLength="50"
                  required
                  value={formValues.monto}
                  onChange={handleChange}
                />
                {console.log(formValues.monto)}
              </div>

            </div>

            <div className="form-row">
              <div className="form-group mt-3 col-sm-12">
                <label className="">Descripcion del Comprobante</label>
                <input
                  type="text"
                  className="form-control"
                  name="descripcion"
                  maxLength="50"
                  required
                  value={formValues.descripcion}
                  onChange={handleChange}
                />
                {console.log(formValues.descripcion)}
              </div>
            </div>

            <div>
              <button
                type="submit"
                className="btn btn-dark mt-5 mb-3"
                id="button"
              >
                Guardar
              </button>
            </div>
          </form>
        </div>
      )
      }

      {/* Modal de éxito estilo imagen */}
      <Modal show={showSuccessModal} onHide={handleSuccessClose} centered>
        <div className="custom-modal">
          <div className="custom-modal-icon">
            <FaThumbsUp /> {/* Manito de "OK" */}
          </div>
          <Modal.Body className="custom-modal-body">
            <h4>Info</h4>
            <p>¡Registro agregado correctamente!</p>
          </Modal.Body>
          <Button className="custom-modal-button" onClick={handleSuccessClose}>
            Aceptar
          </Button>
        </div>
      </Modal>
      
    </>
  );
};

export default AddGastoForm;


