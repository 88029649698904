import React, { useState, useEffect } from "react";
import { modifGasto } from "../helpers/rutaGastos";
import { Modal, Button } from "react-bootstrap";
import { getGastos } from "../helpers/rutaGastos";
import { getProducservs } from "../helpers/rutaProducservs";
import { getProveedores } from "../helpers/rutaProveedores";
import '../css/modalformalert.css'; // Estilos personalizados
import { FaThumbsUp } from "react-icons/fa"; // Importar el ícono de pulgar hacia arriba
import moment from 'moment';

const ModalFormGasto = ({ gasto, handleClose }) => {
  console.log(gasto);
  const id = localStorage.getItem("id");
  const [formValues, setFormValues] = useState({
    nrocomp: gasto.gastos.nrocomp,
    codprov: gasto.gastos.codprov,
    //codprod: gasto.gastos.codprod,
    fechacomp: gasto.gastos.fechacomp,
    monto: gasto.gastos.monto,
    tipo: gasto.gastos.tipo,
    descripcion: gasto.gastos.descripcion,
    //fechapago: "",
    //montopagado: gasto.gastos.montopagado,
    // usuario: id,
  });

  
  const [showSuccessModal, setShowSuccessModal] = useState(false);

  const handleChange = (e) => {
    setFormValues({
      ...formValues,
      [e.target.name]: e.target.value,
    });
  };
  const handleSubmit = (e) => {
    console.log(gasto.gastos._id);
    e.preventDefault();
    modifGasto(formValues, gasto.gastos._id).then((respuesta) => {
      setShowSuccessModal(true);  // Mostrar modal de éxito
    });
  };

  const handleSuccessClose = () => {
    setShowSuccessModal(false);
    handleClose();  // Cerrar modal principal después del éxito
  };

  const [gastos, setGastos] = useState({
    data: {},
    loading: true,
  });
  useEffect(() => {
    getGastos().then((gastos) => {
      setGastos({
        data: gastos,
        loading: false,
      });
    });
  }, []);

  const [proveedores, setProveedores] = useState({
    data: {},
    loading: true,
  });
  useEffect(() => {
    getProveedores().then((proveedores) => {
      setProveedores({
        data: proveedores,
        loading: false,
      });
    });
  }, []);

  const [producservs, setProducservs] = useState({
    data: {},
    loading: true,
  });
  useEffect(() => {
    getProducservs().then((producservs) => {
      setProducservs({
        data: producservs,
        loading: false,
      });
    });
  }, []);

  const formatDateNac = moment(formValues.fechacomp).format('YYYY-MM-DD'); // Ajusta el formato aquí

  return (
    <>
      {!gastos.loading && !producservs.loading && !proveedores.loading && (
        <form onSubmit={handleSubmit}>
          <Modal.Body>
            <div className="form-group mt-3">
              <label>Nro de Comp</label>
              <input
                rows="1"
                type="text"
                className="form-control"
                name="nrocomp"
                maxLength="50"
                minLength="5"
                required
                value={formValues.nrocomp}
                onChange={handleChange}
              
              />
            </div>

            <div className="form-group mt-3">
              <label>Fecha Comprob</label>
              <input
                rows="1"
                type="date"
                className="form-control"
                name="fechacomp"
                required
                value={formatDateNac}
                onChange={handleChange}
              />
            </div>

            <div className="form-group mt-3">
              <label>Proveedor</label>
              <select
                className="form-control"
                name="codprov"
                value={formValues.codprov}
                onChange={handleChange}
                required
              >
                <option selected value="">
                  Elija la opción adecuada
                </option>
                {proveedores.data.proveedores.map((proveedor) => (
                  <option value={proveedor._id}>{proveedor.razonsocial}</option>
                ))}
              </select>
            </div>

            <div className="form-group mt-3">
              <label>Monto</label>
              <input
                rows="1"
                type="text"
                className="form-control"
                name="monto"
                maxLength="100"
                required
                value={formValues.monto}
                onChange={handleChange}
              />
            </div>

            <div className="form-group mt-3">
              <label className="">Sede</label>
              <select
                className="form-control"
                name="tipo"
                value={formValues.tipo}
                onChange={handleChange}
                required
              >
              <option selected value="">
                Elija opción
              </option>
              <option>Sede Norte</option>
                <option>Sede Sur</option>
              </select>
            </div>

            <div className="form-group mt-3">
              <label className="">Descripcion del Comprobante</label>
              <input
                type="text"
                className="form-control"
                name="descripcion"
                maxLength="50"
                required
                value={formValues.descripcion}
                onChange={handleChange}
             />
              {console.log(formValues.descripcion)}
            </div>
           

          </Modal.Body>
          <Modal.Footer>
            <Button type="submit" variant="dark">
              Guardar
            </Button>
          </Modal.Footer>
        </form>
      )}
      ;

      {/* Modal de éxito estilo imagen */}
      <Modal show={showSuccessModal} onHide={handleSuccessClose} centered>
        <div className="custom-modal">
          <div className="custom-modal-icon">
            <FaThumbsUp /> {/* Manito de "OK" */}
          </div>
          <Modal.Body className="custom-modal-body">
            <h4>Info</h4>
            <p>¡Los cambios se guardaron correctamente!</p>
          </Modal.Body>
          <Button className="custom-modal-button" onClick={handleSuccessClose}>
            Aceptar
          </Button>
        </div>
      </Modal>
    </>
  );
};

export default ModalFormGasto;
