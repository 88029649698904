import React, { useState, useEffect } from "react";
import { addGrupo } from "../helpers/rutaGrupos";
//import { getLocalidades } from "../helpers/rutaLocalidades";
import { getGrupos } from "../helpers/rutaGrupos";
//import { getIva } from "../helpers/rutaIva";
import "../css/addclienteform.css";
import { Modal, Button } from "react-bootstrap";
import '../css/modalformalert.css'; // Estilos personalizados
import { FaThumbsUp } from "react-icons/fa"; // Importar el ícono de pulgar hacia arriba



const AddGrupoForm = ({ setShow }) => {
  //const id = JSON.parse(localStorage.getItem("id"));
  const [formValues, setFormValues] = useState({
    grupo: "",
    // activo: "",

    // usuario: id,
  });

  const [showSuccessModal, setShowSuccessModal] = useState(false);

  const handleChange = (e) => {
    setFormValues({
      ...formValues,
      [e.target.name]: e.target.value,
    });
  };
  
  const handleSubmit = (e) => {
    e.preventDefault();
    addGrupo(formValues).then((resp) => {
      console.log(resp);
      setFormValues({
        grupo: "",
        // activo: "",
      });
      // alert("Grabado Exitoso !!!")
      setShowSuccessModal(true);  // Mostrar modal de éxito
      //   setShow(false);
    });
  };

  const handleSuccessClose = () => {
    setShowSuccessModal(false);
      setShow(false);
    // handleClose();  // Cerrar modal principal después del éxito
  };

  const [grupos, setGrupos] = useState({
    data: {},
    loading: true,
  });
  useEffect(() => {
    getGrupos().then((grupos) => {
      setGrupos({
        data: grupos,
        loading: false,
      });
    });
  }, []);

  return (
    <>
      {!grupos.loading && (
        <div className="container">

          <form onSubmit={handleSubmit}>
            <div className="form-group mt-3 col-sm-6">
              <label className="">Grupos</label>
              <input
                type="text"
                className="form-control"
                name="grupo"
                maxLength="30"
                required
                value={formValues.grupo}
                onChange={handleChange}
              />
              {console.log(formValues.grupo)}
            </div>

            <div>
              <button
                type="submit"
                className="btn btn-dark mt-5 mb-3"
                id="button"
              >
                Guardar
              </button>
            </div>
          </form>
        </div>
      )}

      {/* Modal de éxito estilo imagen */}
      <Modal show={showSuccessModal} onHide={handleSuccessClose} centered>
        <div className="custom-modal">
          <div className="custom-modal-icon">
            <FaThumbsUp /> {/* Manito de "OK" */}
          </div>
          <Modal.Body className="custom-modal-body">
            <h4>Info</h4>
            <p>¡Registro agregado correctamente!</p>
          </Modal.Body>
          <Button className="custom-modal-button" onClick={handleSuccessClose}>
            Aceptar
          </Button>
        </div>
      </Modal>
      
    </>
  );
};

export default AddGrupoForm;
