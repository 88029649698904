import React, { useState, useEffect } from "react";
import { modifGasto } from "../helpers/rutaGastos"; 
import { addGastopagado } from "../helpers/rutaGastopagados";
import { Modal, Button } from "react-bootstrap";
import { getGastos } from "../helpers/rutaGastos";
import { getMedios } from "../helpers/rutaMedios";
import { getProveedores } from "../helpers/rutaProveedores";
import moment from 'moment';
import { getComandapagados, addComandapagado, modifComandapagado } from "../helpers/rutaComandapagados";
import { addComandapagadop, modifComandapagadop } from "../helpers/rutaComandapagadops";
import '../css/modalformalert.css'; // Estilos personalizados
import { FaThumbsUp } from "react-icons/fa"; // Importar el ícono de pulgar hacia arriba

const ModalFormComandaActual = ({ comandapagado, handleClose }) => {
  const id = localStorage.getItem("id");

  // Setea la fecha actual en caso de que no haya fecha de pago
  const [formValues, setFormValues] = useState({
    nrocomp: comandapagado.comandapagados.nrocomp,
    codalu: comandapagado.comandapagados.codalu,
    mediopago: comandapagado.comandapagados.mediopago,
    montoaplicado: comandapagado.comandapagados.montoaplicado,
    fechapagop: comandapagado.comandapagados.fechapagop ? comandapagado.comandapagados.fechapagop : moment().format('YYYY-MM-DD'),
    montoaplicadop: comandapagado.comandapagados.montoaplicadop, // Este será de solo lectura
  });

  const [showSuccessModal, setShowSuccessModal] = useState(false);

  // Nuevo campo para el monto que se aplicará al pago
  const [nuevoMontoAplicado, setNuevoMontoAplicado] = useState("");

  const handleChange = (e) => {
    setFormValues({
      ...formValues,
      [e.target.name]: e.target.value,
    });
  };

  // Manejar el cambio en el nuevo campo "Monto Aplicado"
  const handleNuevoMontoChange = (e) => {
    setNuevoMontoAplicado(e.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Incrementar monto pagado con el nuevo valor ingresado
    const nuevoMontoAplicadoNumber = parseFloat(nuevoMontoAplicado) || 0;
    const montoActual = parseFloat(comandapagado.comandapagados.montoaplicadop) || 0;
    const montoTotal = montoActual + nuevoMontoAplicadoNumber;

    // Modificar el gasto existente con el nuevo monto pagado
    await modifComandapagado({ ...formValues, montoaplicadop: montoTotal }, comandapagado.comandapagados._id);
    
    // Crear nuevo modelo de datos gastospagados
    const nuevoComandaPagadop = {
      nrocomp: formValues.nrocomp,
      fechapagop: moment(formValues.fechapagop).format('YYYY-MM-DD'),
      montoaplicadop: nuevoMontoAplicadoNumber,
      mediopago: formValues.mediopago, 
      codalu: formValues.codalu, 
      // gastoReferencia: gasto.gastos._id, // ID del gasto original como referencia
      
    };

    await addComandapagadop(nuevoComandaPagadop); // Asegúrate de que esta función esté implementada
    setShowSuccessModal(true);  // Mostrar modal de éxito
    // handleClose();
  };

  const handleSuccessClose = () => {
    setShowSuccessModal(false);
    handleClose();  // Cerrar modal principal después del éxito
  };

  const formatDatePago = moment(formValues.fechapagop).format('YYYY-MM-DD');

  const [medios, setMedios] = useState({
    data: {},
    loading: true,
  });
  useEffect(() => {
    getMedios().then((medios) => {
      setMedios({
        data: medios,
        loading: false,
      });
    });
  }, []);

  return (
    <>
     {!medios.loading && (
      <form onSubmit={handleSubmit}>
        <Modal.Body>
          <div className="form-group mt-3">
            <label>Fecha Pago</label>
            <input
              type="date"
              className="form-control"
              name="fechapagop"
              required
              value={formatDatePago}
              onChange={handleChange}
            />
          </div>

          <div className="form-group mt-3">
              <label>Medio de Pago</label>
              <select
                className="form-control"
                name="mediopago"
                value={formValues.mediopago}
                onChange={handleChange}
                required
              >
                <option selected value="">
                  Elija la opción adecuada
                </option>
                {medios.data.medios.map((medio) => (
                  <option value={medio._id}>{medio.medio}</option>
                ))}
              </select>
            </div>

          <div className="form-group mt-3">
            <label>Monto Comprobante</label>
            <input
              type="text"
              className="form-control"
              name="montoaplicado"
              value={formValues.montoaplicado}
              readOnly // Hacemos que el campo sea de solo lectura
            />
          </div>

          <div className="form-group mt-3">
            <label>Monto Pagado a la Fecha</label>
            <input
              type="text"
              className="form-control"
              name="montoaplicadop"
              value={formValues.montoaplicadop}
              readOnly // Hacemos que el campo sea de solo lectura
            />
          </div>

          <div className="form-group mt-3">
            <label>Monto que pagara ahora</label>
            <input
              type="text"
              className="form-control"
              name="nuevoMontoAplicado"
              value={nuevoMontoAplicado}
              onChange={handleNuevoMontoChange}
            />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button type="submit" variant="dark">
            Guardar
          </Button>
        </Modal.Footer>
      </form>
       )};

      {/* Modal de éxito estilo imagen */}
      <Modal show={showSuccessModal} onHide={handleSuccessClose} centered>
        <div className="custom-modal">
          <div className="custom-modal-icon">
            <FaThumbsUp /> {/* Manito de "OK" */}
          </div>
          <Modal.Body className="custom-modal-body">
            <h4>Info</h4>
            <p>¡Los cambios se guardaron correctamente!</p>
          </Modal.Body>
          <Button className="custom-modal-button" onClick={handleSuccessClose}>
            Aceptar
          </Button>
        </div>
      </Modal>
    </>
  );
};

export default ModalFormComandaActual;


