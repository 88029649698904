import React from "react";
import { Modal } from "react-bootstrap";
import ModalFormLista from "./ModalFormLista";

const ModalLista = ({ show, handleClose, lista }) => {
  console.log(lista);
  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Modificar Mes/Programa</Modal.Title>
      </Modal.Header>
      <ModalFormLista lista={lista} handleClose={handleClose} />
    </Modal>
  );
};

export default ModalLista;
