import React from "react";
import { Modal } from "react-bootstrap";
import ModalFormMedio from "./ModalFormMedio";

const ModalMedio = ({ show, handleClose, medio }) => {
  console.log(medio);
  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Modificar Medio de Pago</Modal.Title>
      </Modal.Header>
      <ModalFormMedio medio={medio} handleClose={handleClose} />
    </Modal>
  );
};

export default ModalMedio;
