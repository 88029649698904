import React from "react";
// import Principal from "../components/Principal";
//import Login from "../pages/Login";
import Footer from "../components/Footer";
//import logo from "../images/distripollo.jpeg";
import grupo from "../images/grupo.jpg";
import medio from "../images/medio.png";
import cuenta from "../images/cuenta.jpg";
import precio from "../images/precio.jpeg";
import sala from "../images/sala.jpg";
import mes from "../images/mes.png";
import producto from "../images/producto.png";
import proveedor from "../images/avatarproveedor.png";
import { Navbar, Nav, NavDropdown } from "react-bootstrap";
import { Link, useLocation, useHistory } from "react-router-dom";
import "../css/homealtas.css";
import "../css/footer.css";

const HomeImpresion = () => {
  return (
    <>
      {/* <Login /> */}
      <div className="container mt-5">
        <hr />          
        <div className="row text-center mb-3">
          <div className="col-12">
            <h1>Gestion de Altas</h1>
          </div>
        </div>

        <div className="row">
          <div className="col-3 mb-5 mt-5">
            <Link className="nav" to="/grupos">
              <div className="box-wrapper color-docente">
                <img className="logo" src={grupo} alt="logo" href="/clientes" />
                <Navbar.Brand className="mt-3"></Navbar.Brand>
                <h3 className="h3">Grupos</h3>
              </div>
            </Link>
          </div>

          <div className="col-3 mb-5 mt-5">
            <Link className="nav" to="/cuentas">
              <div className="box-wrapper color-cuenta">
                <img className="logo" src={cuenta} alt="logo" href="/clientes" />
                <Navbar.Brand className="mt-3"></Navbar.Brand>
                <h3 className="h3">Cuentas</h3>
              </div>
            </Link>
          </div>

          <div className="col-3 mb-5 mt-5">
            <Link className="nav" to="/producservs">
              <div className="box-wrapper color-cuenta">
                <img className="logo" src={producto} alt="logo" href="/clientes" />
                <Navbar.Brand className="mt-3"></Navbar.Brand>
                <h3 className="h3">Productos</h3>
              </div>
            </Link>
          </div>
 
          <div className="col-3 mb-5 mt-5">
            <Link className="nav" to="/precios">
              <div className="box-wrapper color-cuenta">
                <img className="logo" src={precio} alt="logo" href="/clientes" />
                <Navbar.Brand className="mt-3"></Navbar.Brand>
                <h3 className="h3">Precios</h3>
              </div>
            </Link>
          </div>
        </div>

        <div className="row">

          <div className="col-3 mb-5 mt-5">
            <Link className="nav" to="/salas">
              <div className="box-wrapper color-cuenta">
                <img className="logo" src={sala} alt="logo" href="/clientes" />
                <Navbar.Brand className="mt-3"></Navbar.Brand>
                <h3 className="h3">Salas</h3>
              </div>
            </Link>
          </div>

          <div className="col-3 mb-5 mt-5">
            <Link className="nav" to="/proveedores">
              <div className="box-wrapper color-cuenta">
                <img className="logo" src={proveedor} alt="logo" href="/clientes" />
                <Navbar.Brand className="mt-3"></Navbar.Brand>
                <h3 className="h3">Proveedores</h3>
              </div>
            </Link>
          </div>

          <div className="col-3 mb-5 mt-5">
            <Link className="nav" to="/medios">
              <div className="box-wrapper color-cuenta">
                <img className="logo" src={medio} alt="logo" href="/clientes" />
                <Navbar.Brand className="mt-3"></Navbar.Brand>
                <h3 className="h3">Medio Pago</h3>
              </div>
            </Link>
          </div>

          <div className="col-3 mb-5 mt-5">
            <Link className="nav" to="/listas">
              <div className="box-wrapper color-cuenta">
                <img className="logo" src={mes} alt="logo" href="/clientes" />
                <Navbar.Brand className="mt-3"></Navbar.Brand>
                <h3 className="h3">Mes/Prog</h3>
              </div>
            </Link>
          </div>

          {/* <div className="col-3 mb-5 mt-5">
            <Link className="nav" to="/proveedores">
              <div className="box-wrapper color-proveedore">
                <img className="logo" src={proveedor} alt="logo" href="/clientes" />
                <Navbar.Brand className="mt-3">
                  <h3 className="h3">Proveedores</h3>
                </Navbar.Brand>
              </div>
            </Link>
          </div> */}
        </div>     
      </div>
      
      <Footer />
    </>
  );
};

export default HomeImpresion;
