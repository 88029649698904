import React, { useState, useEffect } from "react";
import { getGastopagados, modificaGastopagado, getGastopagadoId } from "../helpers/rutaGastopagados";
import { delGastopagado } from "../helpers/rutaGastopagados";
import styled from "styled-components";
import { useTable, useBlockLayout } from 'react-table';
import { useSticky } from 'react-table-sticky';
import axios from "axios";
import _ from "lodash";
import Table from "./TableContainerGasto";
import { SelectColumnFilter } from "./Filter";
import ModalGastoActual from "../components/ModalGastoActual";
//import ModalGastoApro from "../components/ModalGastoApro";
import "../css/tablegastos.css";
// import "./App.css";

function AppEgresoReactTable() {
  const [showDatesRange, setShowDatesRange] = useState(false);
  const [show, setShow] = useState(false);
  const [gastopagados, setGastopagados] = useState({
    data: {},
    loading: true,
  });
  const [gastopagado, setGastopagado] = useState({});
  const [data, setData] = useState([]);
  const [carga, setCarga] = useState(false);

  const [fechaDesde, setFechaDesde] = useState('')
  const [fechaHasta, setFechaHasta] = useState('')

  const getRangeFechas = () => {
    setFechaDesde(document.getElementById('inputdesde').value);
    setFechaHasta(document.getElementById('inputhasta').value);
    setShowDatesRange(true);
  }


  useEffect(() => {
    // "https://backditripollo.us-3.evennode.com/comandasactivas"
    // axios.get('http://localhost:3004/comandasinformes', {
    axios.get('https://backdistripruebas.us-3.evennode.com/gastopagadosinformes', {
      params: {
        fechaDesde: fechaDesde,
        fechaHasta: `${fechaHasta}T23:59:59.000Z`,
      }
    })
      .then((res) => {
        setData(res.data.gastopagados);
        setCarga(true);
      })
      .catch((err) => console.log(err));
  }, [fechaDesde, fechaHasta]);

  // useEffect(() => {
  //   axios("https://backdistripruebas.us-3.evennode.com/gastopagados")
  //     .then((res) => {
  //       setData(res.data.gastopagados);
  //     })
  //     .catch((err) => console.log(err));
  // }, []);

  const Styles = styled.div`
  sticky: true;  
  padding: 0rem;
  
  table {
    sticky: true;
    //background-color: #548fcd;
    color: black;
    border-spacing: 0;
    border: 1px solid black;
    font-size: 13px;
    z-index: 1;

    th {
      sticky: true;
      background-color: #778899;
      font-size: 12px;
      text-align: center;
      height: 10rem;
      // position: sticky;
      top: 100;
      z-index: 1;
    }
    ,
    td {
      sticky: true;
      margin: 0;
      padding: 0.5rem;
      border-bottom: 1px solid black;
      border-right: 1px solid black;
      // background-color: #548f0a;
      //background-color: #f0f2eb;
      font-size: 13px;
      // top: 100;
      // z-index: 1;
      

      :last-child {
        border-right: 0;
      }

      input {
        // sticky: true;
        font-size: 1rem;
        padding: 0;
        margin: 0;
        border: 2;
      }
    }
  }

  .pagination {
    padding: 0.5rem;
    //este me da el color de la barra de busqueda
    background-color: #778899;
    font-size: 15px;
    font-weight: bold;
  }
  
  &.sticky {
    overflow: scroll;
    header,
    footer {
      position: sticky;
      z-index: 1;
      // width: fit-content;
    }
  }
    
    .header {
      top: 0;
      box-shadow: 0px 3px 3px #ccc;
      position: sticky;
      z-index:10;
      
    }

    [data-sticky-td] {
      // position: sticky;
      position: absolute;
      z-index: 0;
    }


`;



  // fn filtro fecha

  function dateBetweenFilterFn(rows, id, filterValues) {
    const sd = filterValues[0] ? new Date(filterValues[0]) : undefined;
    const ed = filterValues[1] ? new Date(filterValues[1]) : undefined;

    if (ed || sd) {
      return rows.filter((r) => {
        const cellDate = new Date(r.values[id]);

        if (ed && sd) {
          return cellDate >= sd && cellDate <= ed;
        } else if (sd) {
          return cellDate >= sd;
        } else if (ed) {
          return cellDate <= ed;
        }
      });
    } else {
      return rows;
    }
  }

  // filtro fecha

  
  function DateRangeColumnFilter({
    column: { filterValue = [], preFilteredRows, setFilter, id },
  }) {
    const [min, max] = React.useMemo(() => {
      let min = preFilteredRows.length
        ? new Date(preFilteredRows[0].values[id])
        : new Date(0);
      let max = preFilteredRows.length
        ? new Date(preFilteredRows[0].values[id])
        : new Date(0);

      preFilteredRows.forEach((row) => {
        const rowDate = new Date(row.values[id]);

        min = rowDate <= min ? rowDate : min;
        max = rowDate >= max ? rowDate : max;
      });

      return [min, max];
    }, [id, preFilteredRows]);

    return (
      <div>
        <input
          min={min.toISOString().slice(0, 10)}
          onChange={(e) => {
            const val = e.target.value;
            setFilter((old = []) => [val ? val : undefined, old[1]]);
          }}
          type="date"
          style={{
            width: "100px",
            marginRight: "0.5rem",
            marginBottom: "2.2rem",
          }}
          value={filterValue[0] || ""}
        />
        {/* {" a "} */}
        <input
          max={max.toISOString().slice(0, 10)}
          onChange={(e) => {
            const val = e.target.value;
            setFilter((old = []) => [
              old[0],
              val ? val.concat("T23:59:59.999Z") : undefined,
            ]);
          }}
          type="date"
          style={{
            width: "100px",
            marginRight: "0.5rem",
            marginBottom: "2.2rem",
          }}
          value={filterValue[1]?.slice(0, 10) || ""}
        />
      </div>
    );
  }



  //hasta ca filtro e fecha

  const consultaGastopagados = () => {
    getGastopagados().then((datos) => {
      // console.log(datos);
      setGastopagados({
        data: datos,
        loading: false,
      });
    });
  };

  const modificaGastopagado = (id) => {
    let id_gastopagado = id;
    getGastopagadoId(id_gastopagado).then((resp) => {
      console.log(resp);
      setGastopagado(resp);
      // alert("Comanda Modificada")

      handleShow();
    });
  };

  const handleClose = () => {
    setShow(false);
    consultaGastopagados();
  };

  const handleShow = () => setShow(true);

  const deleteGastopagado = (nrodegastopagado) => {
    // console.log("com", data);
    // console.log("nrodecomanda", nrodecomanda);

    const buscargastopagado = data.filter(function (element) {
      return element.nrodegastopagado === nrodegastopagado;
    });
    //console.log(buscacomanda);

    let validar = window.confirm(
      "Está seguro que desea borrar el Gasto Nro " + nrodegastopagado
    );

    if (validar) {
      for (let i = 0; i < buscargastopagado.length; i++) {
        delGastopagado(buscargastopagado[i]._id).then((resp) => {
          // consultaComandas();
        });

        // aca suma cantidada + stock actual
        // console.log("bc[i].cod._id", buscacomanda[i].codprod._id);
        // debugger;

        // getProducservId(buscaremito[i].codprod._id).then((stk) => {
        //   console.log("stk", stk);
        //   let resstk =
        //     parseInt(stk.producservs.stkactual) -
        //     parseInt(buscaremito[i].cantidad);
        //   console.log("resstk", resstk);
        //   // debugger;
        //   modifProducserv(
        //     { stkactual: resstk },
        //     buscaremito[i].codprod._id
        //   ).then((respuesta) => {
        //     // dejar este console.log
        //     console.log(respuesta);

        //     // alert("Remito Eliminado");
        //     // debugger;
        //   });
        // });
      }
      alert("El Remito fue borrado con Exito")
      window.location.reload();
    }
  };

  console.log(data);
  const columns = [

    // {
    //   sticky: "left",
    //   Header: "Pago Gasto",
    //   width: 50,
    //   // accessor: "modifica",
    //   Cell: (row) => (
    //     <div style={{ textAlign: "center" }}>
    //       <button
    //         id="acepto"
    //         className="btn btn-primary"
    //         onClick={(e) => modificaGasto(row.row.original._id)}
    //       >
    //         <i
    //           className="fa fa-pencil-square-o"
    //           aria-hidden="true"
    //           color="white"
    //         ></i>
    //       </button>
    //       {/* <GetDataInvoiceAdmin datacomanda={row.row.original.nrodecomanda} /> */}
    //     </div>
    //   ),
    // },

    // {
    //   sticky: "left",
    //   Header: "Elim",
    //   width: 50,
    //   // accessor: "elimina",
    //   Cell: (row) => (
    //     <div style={{ textAlign: "center" }}>
    //       <button
    //         id="acepto"
    //         className="btn btn-danger"
    //         onClick={(e) => deleteGasto(row.row.original.nrodedocente)}
    //       >
    //         <i className="fa fa-trash-o" aria-hidden="true" color="white"></i>
    //       </button>
    //       {/* <GetDataInvoiceAdmin datacomanda={row.row.original.nrodecomanda} /> */}
    //     </div>
    //   ),
    // },
    
    {
      //sticky: "left",
      Header: "Cod",
      width: 80,
      accessor: "nrocomp",
      filter: "equals",

      Cell: (row) => <div style={{ textAlign: "center" }}>{row.value}</div>,
    },

    {
      id: "fechapago",
      Header: "Fecha Pago",
      width: 110,
      style: { marginBottom: "2rem" },
      accessor: "fechapago",
      Cell: (row) => (
        <div style={{ textAlign: "center" }}>{row.value.slice(0, 10)}</div>
      ),

      Filter: DateRangeColumnFilter,
      // filter: "between",
      filter: dateBetweenFilterFn,
    },

    {
      Header: "Proveedor",
      id: "codprov",
      accessor: "gastoReferencia.codprov.razonsocial",
      width: "300",
    },

    {
      Header: "Sede",
      id: "tipo",
      accessor: "gastoReferencia.tipo",
      width: "100",
    },

    {
      Header: "Medio de Pago",
      id: "medio",
      accessor: "mediopago.medio",
      width: "200",
    },

    {
      id: "fechacomp",
      Header: "Fecha Comp",
      width: 110,
      style: { marginBottom: "2rem" },
      accessor: "gastoReferencia.fechacomp",
      Cell: (row) => (
        <div style={{ textAlign: "center" }}>{row.value.slice(0, 10)}</div>
      ),

      Filter: DateRangeColumnFilter,
      // filter: "between",
      filter: dateBetweenFilterFn,
    },



    // {
    //   Header: "Producto",
    //   id: "Producto",
    //   accessor: "codprod.descripcion",
    //   width: "200",
    // },

    // {
    //   Header: "Sede",
    //   accessor: "tipo",
    //   width: "100",
    // },

    // {
    //   Header: () => (
    //     <div
    //       style={{
    //         textAlign: "center",
    //       }}
    //     >
    //       Monto
    //     </div>
    //   ),

    //   accessor: "monto",
    //   width: "130",

    //   Cell: (props) =>
    //     new Intl.NumberFormat("es-AR", {
    //       style: "currency", 
    //       currency: "ARS",
    //     }).format(props.value),

    //   Footer: (info) => {
    //     // Only calculate total visits if rows change
    //     const total = React.useMemo(
    //       () => info.rows.reduce((sum, row) => row.values.monto + sum, 0),
    //       [info.rows]
    //     );

    //     return (
    //       <div className="pie" style={{ textAlign: "right" }}>
    //         {new Intl.NumberFormat("es-AR", {
    //           style: "currency",
    //           currency: "ARS",
    //         }).format(total)}
    //       </div>
    //     );
    //   },

    //   Cell: (row) => (
    //     <div style={{ textAlign: "right" }}>
    //       {row.value !== "2000-01-01T00:00:00.000+00:00"
    //       ?
    //       new Intl.NumberFormat("es-AR", {
    //         style: "currency",
    //         currency: "ARS",
    //       }).format(row.value)
    //       : null /* O puedes reemplazar null por un string vacío */}
    //     </div>
    //   ),

    //   // Cell: (row) => <div style={{ textAlign: "right" }}>{row.value}</div>,
    // },

    // {
    //   id: "fechapago",
    //   Header: "Fecha Pago",
    //   width: 110,
    //   style: { marginBottom: "2rem" },
    //   accessor: "fechapago",
    //   Cell: (row) => (
    //     <div style={{ textAlign: "center" }}>{row.value.slice(0, 10)}</div>
    //   ),

    //   Filter: DateRangeColumnFilter,
    //   // filter: "between",
    //   filter: dateBetweenFilterFn,
    // },




    
    {
      Header: () => (
        <div
          style={{
            textAlign: "center",
          }}
        >
          Monto Aplicado
        </div>
      ),

      accessor: "montoaplicado",
      width: "130",
      id: "montoaplicado",

      Cell: (props) =>
        new Intl.NumberFormat("es-AR", {
          style: "currency", 
          currency: "ARS",
        }).format(props.value),

      Footer: (info) => {
        // Only calculate total visits if rows change
        const montoaplicado = React.useMemo(
          () => info.rows.reduce((sum, row) => row.values.montoaplicado + sum, 0),
          [info.rows]
        );

        return (
          <div className="pie" style={{ textAlign: "right", color: "blue" }}>
            {new Intl.NumberFormat("es-AR", {
              style: "currency",
              currency: "ARS",

            }).format(montoaplicado)}
          </div>
        );
      },

      Cell: (row) => (
        <div style={{ textAlign: "right", color: "blue" }}>
          {row.value !== 0
            ? new Intl.NumberFormat("es-AR", {
                style: "currency",
                currency: "ARS",
              }).format(row.value)
            : null /* O puedes reemplazar null por un string vacío */}
        </div>
      ),


    },


    // {
    //   Header: () => (
    //     <div
    //       style={{
    //         textAlign: "center",
    //       }}
    //     >
    //       Saldo por Pagar
    //     </div>
    //   ),
    
    //   accessor: "saldo", // Accesor personalizado para saldo
    //   width: "130",

    //   Cell: (props) => {
    //     const monto = props.row.values.monto;
    //     const montopagado = props.row.values.montopagado;
    //     const saldo = monto - montopagado;
    
    //     return ( 
    //       <div className="" style={{ textAlign: "right", color: "red" }}>
    //        {new Intl.NumberFormat("es-AR", {
    //           style: "currency",
    //           currency: "ARS",
    //         }).format(saldo)}
    //       </div>
    //   );
    //   },
    
    //   Footer: (info) => {
    //     // Cálculo del saldo total
    //     const totalSaldo = React.useMemo(
    //       () =>
    //         info.rows.reduce(
    //           (sum, row) => row.values.monto - row.values.montopagado + sum,
    //           0
    //         ),
    //       [info.rows]
    //     );
    
    //     return (
    //       <div className="pie" style={{ textAlign: "right", color: "red" }}>
    //         {new Intl.NumberFormat("es-AR", {
    //           style: "currency",
    //           currency: "ARS",
    //         }).format(totalSaldo)}
    //       </div>
    //     );
    //   },
    

    // },
    





  ];

  const today = new Date();
  const numberOfDaysToAdd = 0;
  const date = today.setDate(today.getDate() + numberOfDaysToAdd);
  const fechaHoy = new Date(date).toISOString().split('T')[0]

  return (
    <>
      {!showDatesRange &&
        <form>
          <div className="row justify-content-center">
            <div className="col-6">
              <h3 className="mt-3">INGRESE PERIODO - 
                Desde: <input 
                        type="date" 
                        name="fdesde" 
                        id="inputdesde"
                        defaultValue={fechaHoy} 
                        required 
                        /> -
                Hasta: <input 
                        type="date" 
                        name="fhasta" 
                        id="inputhasta" 
                        defaultValue={fechaHoy} 
                        required/>
              </h3>
            </div>
            <div className="col-2">
              <button 
                className="btn btn-danger ml-5 mt-2 mb-2 justify-content-end" 
                onClick={() => getRangeFechas()}> Aceptar </button>
              </div>
          </div>  
        </form>
      }

    
    {showDatesRange &&
       <>
      {!carga && <h2 className="cargando mt-3 ml-1"> Cargando... Espere...</h2>}
      <div className="row justify-content-center">
      <div className="col-4">
              <h2 className="mt-2">EGRESOS DEL: {fechaDesde}  -  AL: {fechaHasta}</h2>
            </div>
        <div className="col-2">
          <button          
            className="btn btn-primary mr-5 mt-2 mb-2 justify-content-end"    
            onClick={() => window.location.reload()}
          >
            Recargar Pagina...
          </button>
        </div>
      </div>
      <Styles className="container table sticky" style={{ width: "auto", height: 400 }}>
        <div className="App">
          <Table columns={columns} data={data} />
        </div>
        {/* <ModalGastoApro show={show} handleClose={handleClose} gasto={gasto} /> */}
        <ModalGastoActual show={show} handleClose={handleClose} gastopagado={gastopagado} />
      </Styles>
    </>
  }
     </>
  );
}

export default AppEgresoReactTable;
