import React, { useState, useEffect } from "react";
import { Table } from "react-bootstrap";
import { getSalas, delSala, getSalaId } from "../helpers/rutaSalas";
import ModalSala from "./ModalSala";
import "../css/tablesalas.css";

const TableSalas = () => {
  let id_sala= "";

  const [salas, setSalas] = useState({
    data: {},
    loading: true,
  });

  const [sala, setSala] = useState({});
  const [show, setShow] = useState(false);

  useEffect(() => {
    consultaSalas();
  }, []);

  const consultaSalas = () => {
    getSalas().then((datos) => {
      console.log(datos);
      setSalas({
        data: datos,
        loading: false,
      });
    });
  };

  // console.log(comandas.data[0]);

  const handleClose = () => {
    setShow(false);
    consultaSalas();
  };

  const handleShow = () => setShow(true);

  const modificaSala= (id) => {
    id_sala = id;
    getSalaId(id_sala).then((resp) => {
      console.log(resp);
      setSala(resp);

      handleShow();
    });
  };

  const deleteSala = (id) => {
    let validar = window.confirm("está seguro que desea borrar la sala?");
    if (validar) {
      delSala(id).then((resp) => {
        consultaSalas();
      });
    }
  };

  let count = 0;

  return (
    <div className="table-container">
      {!salas.loading && (
        <>
          <div className="col-12 mt-4"></div>
          <div>
            <Table striped bordered hover className="table-container">
              <thead>
                <tr className="header">
                  <th className="th">Modif/Elim</th>
                  <th className="sal">Sala</th>
                  <th className="tur">Turno</th>
                  <th></th>
                </tr>
              </thead>

              <tbody>
                {salas.data.salas.map((sala) => (
                  <>
                    {sala.activo && (
                      <tr key={sala._id}>
                        <td className="sticky1">
                          <button
                            id="acepto"
                            className="btn btn-primary"
                            onClick={() => {
                              modificaSala(sala._id);
                            }}
                          >
                            <i
                              className="fa fa-pencil-square-o"
                              aria-hidden="true"
                            ></i>
                          </button>
                          <button
                            id="acepto"
                            className="btn btn-danger ml-2"
                            onClick={() => {
                              deleteSala(sala._id);
                            }}
                          >
                            <i className="fa fa-trash-o" aria-hidden="true"></i>
                          </button>
                        </td>
                        <td className="sal">{sala.sala}</td>
                        <td className="tur">{sala.turno}</td>
                         {/* <td>{cliente.lista.lista}</td>
                    <td>{cliente.codprod.descripcion}</td> */}
                        {/* <td>{usuario.nombre}</td>*/}
                        {/* {(count = count + 1)} */}

                      </tr>
                    )}
                  </>
                ))}
              </tbody>
            </Table>
            {/* <h1>{count}</h1> */}
          </div>
          <ModalSala
            show={show}
            handleClose={handleClose}
            sala={sala}
          />
        </>
      )}
    </div>
  );
};

export default TableSalas;
