import React, { useEffect, useState, useRef } from "react";
import { useHistory } from "react-router-dom";
import { postLogin } from "../helpers/rutaUsuarios";
import "../css/loginform.css";

const LoginForm = () => {
  const history = useHistory();
  const [formValues, setFormValues] = useState({
    email: "",
    password: "",
  });

  const [user, setUser] = useState({
    data: { ok: null },
    loading: false,
  });

  const logoutButtonRef = useRef(null); // Usamos una referencia para el botón de logout

  useEffect(() => {
    if (user.data.ok) {
      // Guardar el token y otros datos en localStorage
      localStorage.setItem("token", JSON.stringify(user.data.token));
      localStorage.setItem("id", user.data.usuario._id);
      localStorage.setItem(
        "usuario",
        JSON.stringify(user.data.usuario.nombres)
      );

      // Redirigir a la página principal después del login
      history.push("./");

      // Iniciar el temporizador de inactividad
      resetTimer();
    }
  }, [user, history]);

  // Función para cerrar sesión por inactividad
  const logout = () => {
    // Simulamos un clic en el botón de logout usando la referencia
    if (logoutButtonRef.current) {
      logoutButtonRef.current.click();
    }
  };

  // Resetea el temporizador cada vez que hay interacción del usuario
  const resetTimer = () => {
    clearTimeout(window.inactivityTimeout);
    window.inactivityTimeout = setTimeout(logout, 60 * 1000); // 1 minuto de inactividad
  };

  // Configura los listeners de actividad del usuario
  useEffect(() => {
    // Agregar eventos para detectar actividad (mouse y teclado)
    window.addEventListener("mousemove", resetTimer);
    window.addEventListener("keydown", resetTimer);

    // Limpia los eventos y el temporizador cuando el componente se desmonta
    return () => {
      window.removeEventListener("mousemove", resetTimer);
      window.removeEventListener("keydown", resetTimer);
      clearTimeout(window.inactivityTimeout);
    };
  }, []);

  const handleChange = (e) => {
    setFormValues({
      ...formValues,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    setUser({
      ...user,
      loading: true,
    });

    postLogin(formValues).then((datos) => {
      setUser(datos);
    });

    setFormValues({
      email: "",
      password: "",
    });
  };

  return (
    <>
      <main>
        <div className="row">
          <div className="row-15">
            <div className="col-12">
              <form onSubmit={handleSubmit}>
                <div className="form-group">
                  <label>Correo electrónico</label>
                  <input
                    id="email"
                    type="email"
                    className="form-control"
                    name="email"
                    value={formValues.email}
                    onChange={handleChange}
                  />
                </div>
                <div className="form-group mt-2">
                  <label>Contraseña</label>
                  <input
                    id="password"
                    type="password"
                    className="form-control"
                    name="password"
                    value={formValues.password}
                    onChange={handleChange}
                  />
                </div>

                <button
                  id="boton"
                  type="submit"
                  className="btn btn-info btn-block mt-5"
                  disabled={user.loading}
                >
                  Enviar
                </button>
              </form>
              {user.data.ok === false && (
                <div className="alert alert-dark mt-3 text-center" role="alert">
                  {user.data.err.message}
                </div>
              )}
            </div>
          </div>
        </div>
        {/* Botón de cerrar sesión */}
        <button
          ref={logoutButtonRef} // Referencia al botón de logout
          onClick={() => {
            localStorage.removeItem("token");
            localStorage.removeItem("id");
            localStorage.removeItem("usuario");
            history.push("/login");
          }}
          style={{ display: "none" }} // El botón está oculto
        >
          Cerrar sesión
        </button>
      </main>
    </>
  );
};

export default LoginForm;


// import React, { useEffect, useState } from "react";
// import { useHistory } from "react-router-dom";
// import { postLogin } from "../helpers/rutaUsuarios";
// import Quienes from "../pages/Quienes";
// // import AppCamionReactTable from "../table/AppCamionReactTable";
// import App from "../App";
// import "../css/loginform.css";
// import Comandas from "../pages/Comandas";

// const LoginForm = () => {
//   const history = useHistory();
//   const [formValues, setFormValues] = useState({
//     email: "",
//     password: "",
//   });

//   const [user, setUser] = useState({
//     data: { ok: null },
//     loading: false,
//   });

//   useEffect(() => {
//     if (user.data.ok) {
//       localStorage.setItem("token", JSON.stringify(user.data.token));
//       localStorage.setItem("id", user.data.usuario._id);
//       localStorage.setItem(
//         "usuario",
//         JSON.stringify(user.data.usuario.nombres)
//       );

//       history.push("./");
//     }
//   }, [user, history]);

//   const handleChange = (e) => {
//     setFormValues({
//       ...formValues,
//       [e.target.name]: e.target.value,
//     });
//   };

//   const handleSubmit = (e) => {
//     e.preventDefault();

//     setUser({
//       ...user,
//       loading: true,
//     });

//     postLogin(formValues).then((datos) => {
//       setUser(datos);
//     });
//     setFormValues({
//       email: "",
//       password: "",
//     });
//   };

//   return (
//     <>
//       <main>
//         <div className="row">
//           <div className="row-15">
//             <div className="col-12">
//               <form onSubmit={handleSubmit}>
//                 <div className="form-group">
//                   <label className="">Correo electrónico</label>
//                   <input
//                     id="email"
//                     type="email"
//                     className="form-control"
//                     name="email"
//                     value={formValues.email}
//                     onChange={handleChange}
//                   />
//                 </div>
//                 <div className="form-group mt-2">
//                   <label className="">Contraseña</label>
//                   <input
//                     id="password"
//                     type="password"
//                     className="form-control"
//                     name="password"
//                     value={formValues.password}
//                     onChange={handleChange}
//                   />
//                 </div>

//                 <button
//                   id="boton"
//                   type="submit"
//                   className="btn btn-info btn-block mt-5"
//                   disabled={user.loading}
//                 >
//                   Enviar
//                 </button>
//               </form>
//               {console.log(user.data.ok)}
//               {user.data.ok === false && (
//                 <div className="alert alert-dark mt-3 text-center" role="alert">
//                   {user.data.err.message}
//                 </div>
//               )}

//               {user.data.ok === true}
   
//             </div>
//           </div>
//         </div>
//       </main>
//     </>
//   );
// };

// export default LoginForm;
