import React from "react";
import { Modal } from "react-bootstrap";
import ModalFormSala from "./ModalFormSala";

const ModalSala = ({ show, handleClose, sala }) => {
  console.log(sala);
  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Modificar Sala</Modal.Title>
      </Modal.Header>
      <ModalFormSala sala={sala} handleClose={handleClose} />
    </Modal>
  );
};

export default ModalSala;
