import React from "react";
// import TableComandas from "../components/TableComandas";
import AppIngresoReactTable from "../table/AppIngresoReactTable";

import Footer from "../components/Footer";

import "../css/footer.css";

const Gastos = () => {
  return (
    <>
      <AppIngresoReactTable />
      {/* <TableComandas /> */}
      <Footer />
    </>
  );
};

export default Gastos;
